import {request} from './request_loc'
import Cookies from 'js-cookie'
import qs from 'qs'

export function getList(page,parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/getList?page='+page+'&maker_id='+maker_id,method:'post',data:qs.stringify(parameter)})
}
export function saveLogisticsTrackingNumber(parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/saveLogisticsTrackingNumber?maker_id='+maker_id,method:'post',data:qs.stringify(parameter)})
}
export function dowloadCsv(parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/dowloadCsv?maker_id='+maker_id,method:'post',data:qs.stringify(parameter)})
}
export function add(parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/add?maker_id='+maker_id,method:'post',data:qs.stringify(parameter)})
}
export function getSKU() {
    let maker_id = Cookies.get("maker_id");
    return request({url:'/order3/getSKU',params:{maker_id}});
}
export function del(order_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/del',params:{maker_id,order_id}});
}
export function getDetail(order_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/getDetail',params:{maker_id,order_id}});
}
export function update(parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/update?maker_id='+maker_id,method:'post',data:qs.stringify(parameter)});
}
export function cancelReason(parameter) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/order3/cancel?maker_id='+maker_id,method:'post',data:qs.stringify(parameter)});
}