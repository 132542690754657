var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medialist"},[_c('a-page-header',{staticClass:"header_title",attrs:{"title":_vm.$t('orders.add.title')},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_1')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orders',  { rules: [{ required: true, message: _vm.$t('orders.add.alert_msg') }]}]),expression:"['orders',  { rules: [{ required: true, message: $t('orders.add.alert_msg') }]}]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_2')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'product_code',
                  { rules: [{ required: true, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                  'product_code',\n                  { rules: [{ required: true, message: $t('orders.add.alert_msg') }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.data),function(d){return _c('a-select-option',{key:d.sku},[_vm._v(" "+_vm._s(d.sku)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_3')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'order_number',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'order_number',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_4')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'product_model',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'product_model',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_5')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'country',
                    { rules: [{ required: false, message:_vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'country',\n                    { rules: [{ required: false, message:$t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_6')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'addressee',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'addressee',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_7')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'recipient_address',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'recipient_address',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_8')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'telephone_number',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'telephone_number',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_9')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'postal_code',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'postal_code',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_10')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'quantity',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'quantity',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_11')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['shipped_date',  { rules: [{ required: true, message: _vm.$t('orders.add.alert_msg') }]}]),expression:"['shipped_date',  { rules: [{ required: true, message: $t('orders.add.alert_msg') }]}]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_12')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'service_provider',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'service_provider',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('orders.add.item_13')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'logistics_tracking_number',
                    { rules: [{ required: false, message: _vm.$t('orders.add.alert_msg') }] } ]),expression:"[\n                    'logistics_tracking_number',\n                    { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },\n                ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"align":"center","wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('orders.add.btn_submit'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }