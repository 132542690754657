<template>
    <div class="medialist">
        <a-page-header
            :title="$t('orders.add.title')"
            class="header_title"
            @back="() => $router.go(-1)"
        />
        <a-spin :spinning="spinning">
        <!-- <div class="box_content"> -->
          <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" @submit="handleSubmit">
              <a-form-item :label="$t('orders.add.item_1')">
                <a-date-picker style='width:100%' v-decorator="['orders',  { rules: [{ required: true, message: $t('orders.add.alert_msg') }]}]"/>
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_2')">
                <!-- <a-input
                    placeholder=""
                    v-decorator="[
                        'product_code',
                        { rules: [{ required: true, message: $t('orders.add.alert_msg') }] },
                    ]"
                /> -->
                <a-select style="width: 100%" v-decorator="[
                      'product_code',
                      { rules: [{ required: true, message: $t('orders.add.alert_msg') }] },
                  ]" >
                  <a-select-option v-for="d in data" :key="d.sku">
                    {{ d.sku }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_3')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'order_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_4')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'product_model',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_5')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'country',
                        { rules: [{ required: false, message:$t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_6')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'addressee',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_7')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'recipient_address',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_8')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'telephone_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_9')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'postal_code',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_10')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'quantity',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_11')">
                <!-- <a-input
                    placeholder=""
                    v-decorator="[
                        'shipped_date',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                /> -->
                <a-date-picker style='width:100%' v-decorator="['shipped_date',  { rules: [{ required: true, message: $t('orders.add.alert_msg') }]}]"/>
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_12')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'service_provider',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.add.item_13')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'logistics_tracking_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <!-- <a-form-item :label="$t('orders.add.item_14')">
                <a-upload
                  name="file"
                  :multiple="true"
                  :headers="headers"
                  action="https://shady.ecomgear.cn/kenneth_testing/maker/admin/index.php/upload"
                  @change="handleChange"
                >
                  <a-button v-if="isShow"> <a-icon type="upload" /> Click to Upload </a-button>
                </a-upload>
              </a-form-item> -->
              
              <a-form-item align="center" :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit">
                    {{$t('orders.add.btn_submit')}}
                </a-button>
              </a-form-item>

            </a-form>
        <!-- </div> -->
        </a-spin>
    </div>
</template>

<script>
import {
  getSKU,
  add,
} from "@/network/order3";

export default {
  name: 'NodeprojectMedia',
  components: {  },
  directives: {  },
  data() {
    return {
      data:[],
      spinning:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this,{name:'coordinated'}),
      headers: {
        'X-Requested-With': null,
      },
      path:'',
      isShow:true,
    };
  },
  created() {
    this.getSKU();
  },
  mounted() {
    
  },
  methods: {
    handleChange(info) {
      console.log(info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'removed') {
        this.isShow = true;
        this.path = '';
      }else if (info.file.status === 'done') {
        if(info.file.response.code == 0){
          this.isShow = false;
          this.path = info.file.response.path;
        }
        this.$message.success('file uploaded successfully');
      } else if (info.file.status === 'error') {
        this.$message.error('file upload failed');
      }
    },
    getSKU(){
      getSKU().then(res=>{
        if(res.code==0){
          this.data = res.data;
        }
      });
    },
    handleSubmit(e){
      e.preventDefault();
      this.form.validateFields((err, values) => {
        let fieldsValue = values;
        if (!err) {
          const values = {
          ...fieldsValue,
          'order_date': fieldsValue['order_date'].format('YYYY-MM-DD'),
          'shipped_date': fieldsValue['shipped_date'].format('YYYY-MM-DD'),
          // 'dow_image': this.path,
          }
          add(values).then(res=>{
              if(res.code==0){
              // this.$message.success("success!");
              // this.$router.back(-1)
              //     return ;
              const that = this;
              this.$message.success(res.msg,1).then(()=>{
                    this.$success({
                        title: 'success',
                        content: ( 
                            <div>
                                <a href="https://ship13.shipstation.com/login?firsttime=true" target="_blank">Click Login ShipStation</a>
                            </div>
                        ),
                        onOk() {
                          that.$router.back(-1);
                        },
                    });
                });
              }else{
                this.$message.success(res.msg);
              }
          });
        }

      });
    },
  },
};
</script>

<style  scoped>

</style>